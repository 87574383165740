// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-cgu-js": () => import("/home/maxime/workspace/selfcity/plombiers-toulousains-website/src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-charte-de-confidentialite-js": () => import("/home/maxime/workspace/selfcity/plombiers-toulousains-website/src/pages/charte-de-confidentialite.js" /* webpackChunkName: "component---src-pages-charte-de-confidentialite-js" */),
  "component---src-pages-index-js": () => import("/home/maxime/workspace/selfcity/plombiers-toulousains-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("/home/maxime/workspace/selfcity/plombiers-toulousains-website/src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/maxime/workspace/selfcity/plombiers-toulousains-website/.cache/data.json")

